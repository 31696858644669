import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './features/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from './features/forms/forms.module';
import { TableModule } from './features/table/table.module';
import { FileModule } from './features/file/file.module';
import { SnackBarProgressComponent } from './shared/components/snack-bar-progress/snack-bar-progress.component';
import { ButtonFavoriteComponent } from './shared/components/button-favorite/button-favorite.component';
import { AddressCardComponent } from './shared/components/address-card/address-card.component';
import { NgxMaskModule } from 'ngx-mask'
import { OrderCardComponent } from './shared/components/order-card/order-card.component';
import { ProductItemCartComponent } from './shared/components/product-item-cart/product-item-cart.component';
import { RouterModule } from '@angular/router';
import { EmptyListComponent } from './shared/components/empty-list/empty-list.component';
import { StatusStepperComponent } from './shared/components/status-stepper/status-stepper.component';
import { DialogConfirmComponent } from './shared/components/dialog-confirm/dialog-confirm.component';
import { OrdersTableComponent } from './shared/components/orders-table/orders-table.component';
import { SwiperModule } from 'swiper/angular';
import { ProductAddComponent } from './shared/components/product-add/product-add.component';
import { NgxEditorModule } from 'ngx-editor';
import { BlockCopyPasteDirective } from './shared/directives/block-copy-paste.directive';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UserInfoButtonComponent } from './shared/components/user-info-button/user-info-button.component';
import { ShareModule } from 'ngx-sharebuttons';
import { MessagesFormComponent } from './shared/components/messages-form/messages-form.component';
import { ImageModalComponent } from './shared/components/image-modal/image-modal.component';

@NgModule({
  declarations: [
    SnackBarProgressComponent,
    ButtonFavoriteComponent,
    AddressCardComponent,
    OrderCardComponent,
    ProductItemCartComponent,
    EmptyListComponent,
    StatusStepperComponent,
    DialogConfirmComponent,
    OrdersTableComponent,
    ProductAddComponent,
    BlockCopyPasteDirective,
    PdfViewerComponent,
    UserInfoButtonComponent,
    MessagesFormComponent,
    ImageModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    TableModule,
    FileModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    SwiperModule,
    PdfViewerModule,
    NgxEditorModule.forRoot({
      locals: {
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        underline: 'Underline',
        // ...
      }
    }),
    ShareModule,
  ],
  exports: [
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    TableModule,
    FileModule,
    PdfViewerModule,
    SnackBarProgressComponent,
    ButtonFavoriteComponent,
    AddressCardComponent,
    OrderCardComponent,
    ProductItemCartComponent,
    EmptyListComponent,
    StatusStepperComponent,
    DialogConfirmComponent,
    OrdersTableComponent,
    ProductAddComponent,
    PdfViewerComponent,
    UserInfoButtonComponent,
    MessagesFormComponent,
    ImageModalComponent,
    NgxMaskModule,
    SwiperModule,
    ShareModule,
    BlockCopyPasteDirective,
  ]
})
export class SharedModule { }
